import axios from 'axios'
import Routing from '@js/Routing'
import type { MessageBag } from '@js/stores/notifications'

export function login(payload: { username: string; password: string; rememberMe: boolean }) {
  return axios.post<{ messages: MessageBag; two_factor_complete?: false }>('/api/login', {
    username: payload.username,
    password: payload.password,
    _remember_me: payload.rememberMe,
  })
}

export function logout() {
  return axios.get<Record<string, never>>('/api/logout')
}

export function confirmTwoFactor(payload: { authCode: string; trustDevice: boolean }) {
  return axios.post<{ messages: MessageBag }>(
    Routing.generate('two_factor_authentication_login_check'),
    {
      _auth_code: payload.authCode,
      _trusted: payload.trustDevice,
    }
  )
}

export function refreshJwt() {
  return axios.get<{ message: string }>(Routing.generate('u2_refreshjwt__invoke'))
}
